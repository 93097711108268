import _ from "lodash";
import useFormValues from "../../../../../../hooks/useFormValues";
import { finalJudgmentDefaultFormValues } from "../constants/defaultValues";

const useFinalJudgment = () => {

  const finalJudgmentFormMethods = useFormValues(finalJudgmentDefaultFormValues);
 
  const formValues = {
    ...finalJudgmentFormMethods.formValues,
  }
  const setInitialState = (initialState) => {
    const doesInitialStateExist = _.isObject(initialState);
    if (!doesInitialStateExist) {
      return
    }

    finalJudgmentFormMethods.setFormValues({ ...finalJudgmentFormMethods.formValues, ...initialState });
  }

  const assertions = {
    isASenseResolutionpProceeded: finalJudgmentFormMethods.formValues.sense_resolution === 'proceed',
    isNotASenseResolutionpProceeded: finalJudgmentFormMethods.formValues.sense_resolution === 'no_proceed',
  }

  return {
    finalJudgmentFormMethods,
    formValues,
    setInitialState,
    assertions,
  }
}

export default useFinalJudgment