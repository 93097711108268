import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { RemotePagination } from "../../helper_components/remote_pagination/RemotePagination";
import { Grid } from "react-flexbox-grid";
import NavBar from "../../layout/shared/NavBar";
import ExpedientsSearcher from "../../helper_components/expedients_searcher/ExpedientSearcher";
import { Button, Form, Card, Container, Row } from "react-bootstrap";
import ModalComponent from "../../helper_components/ModalComponent";
import Select from "react-select";
import {
  columns, getDocumentsByExpedientNumber, getFilteredDocumentTypes,
  getPromotionsWithoutAgreement, bodyModalDocuments, bodyModalExpiration,
  footerModalDocuments, getEvidencesDocument, getAgreementTypes,
  getAllDocumentsOfExpedient, bodyDocumentsView, footerDocumentsView, getEmailsTemplates,
  bodyModalNotifications, footerModalNotifications, searchDocumentsByExpedientNumberAndFilename, dowloadExpedient,
  getAgreementsToNotifyByExpedientId,setExpedientParts,
  getExpedientParts, getStringIdFromASelectArray, getIsCommentFieldShowingInFile, printCover,
} from "./expedient_details_logic";
import Dropzone from "react-dropzone";
import { fieldsValidation, renderErrorsByInputName, selectStylesDefault } from "../my_expedients/expedients_list_logic";
import LoaderContext from "../../layout/shared/loader_context";
import Loader from "../../layout/shared/Loader";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import Cookies from "js-cookie";
import Footer from "../../layout/shared/Footer";
import TableUploadManyFiles from "../../helper_components/TableUploadManyFiles";
import PreviewTemplates from "../PreviewTemplates";
import TemplatesPresaved from "../templatesPresaved/TemplatesPresaved";
import AssignUserModal from "./modals/AssignUsers";
import { getUsersWithPermissionsToSeeExpedient } from "./modals/assign_users_logic";
import FullCommentModal from "../../helper_components/full_comment/FullCommentModal";
import NotifyEmailModal from "./modals/NotifyEmailModal";
import FilesPendingSignatureTable from "./components/filesPendingSignatureTable/FilesPendingSignatureTable";
import ToggleButton from "../../layout/shared/ToggleButton";
import { useCommentsModalContext } from "./components/filesPendingSignatureTable/context/CommentsModalContext";
import UsersAndPermissionsListModal from "./modals/UsersAndPermissionsListModal";
import ModalComponentPortal from "../../helper_components/modals/modalComponentPortal/ModalComponentPortal";
import { handleRequestSendDataNotificationEmail } from "./modals/notify_email_modal_logic";
import TextTooltip from "../../tootip/TextTooltip";
import AlertModal from "./modals/AlertModal";
import ChangeCourtTable from "./components/changeCourtTable/ChangeCourtTable";
import IndicatorsModal from "./modals/indicatorsModal/IndicatorsModal";
import { getIsAValidMaxFileSize } from "../../../services/assert/getIsAValidMaxFileSize";
import SimpleSignatureAlert from "./modals/SimpleSignatureAlert";
import { useFilesPendingSignatureContext } from "./components/filesPendingSignatureTable/context/FilesPendingSignatureContext";
import { useElectronicSignatureProcessContext } from "../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/context/ElectronicSignatureProcessContext";
import CredentialsModal from "../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/credentialsModal/CredentialsModal";
import ElectronicSignatureTable from "../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/componets/ElectronicSignatureTable";
import _ from "lodash";
import { objectToFormData } from "../../../services/formData/objectToFormData";
import { mergeFormData } from "../../../services/formData/mergeFormData";
import { onUploadDocumentWithMultisignature } from "../../../services/electronicSignature/multiSignature/onUploadDocumentWithMultisignature";
import { onSaveSignatureToTheListOfSignatures } from "../../../services/electronicSignature/multiSignature/onSaveSignatureToTheListOfSignatures";
import { getElectronicSignatureProperties } from "../../../services/electronicSignature/getElectronicSignatureProperties";
import TemplatesPresavedContextProvider from "./context/templatesPresavedContext";
import { electronicSignatureSettingsFirel } from "../../dynamic_form/dynamic_form_types/ElectronicSignatureProcess/constants/electronicSignatureSettingsFirel";
import { getCanTypeOfDocumentBeSigned } from "./services/getCanTypeOfDocumentBeSigned";
import { showAlertForDocumentToBeSignedNotAllowed } from "./services/showAlertForDocumentToBeSignedNotAllowed";
import { getFileWithExtensionInLowercase } from "./services/getFileWithExtensionInLowercase";
import { useMultipleElectronicSignatureModalContext } from "./components/filesPendingSignatureTable/componets/electronicSignatureModal/context/MultipleElectronicSignatureModalContext";
import { useExpedientDetailsContext } from "./context/expedientDetailsContext";
import Cover from "../../layout/shared/Cover";
import TransferModal from "../../transferModal/TransferModal";
import useTransferState from "../../transferModal/hooks/useTransferState";
import { BulletinFields } from "./components/BulletinFields/BulletinFields";

import { PartiesModal } from "./modals/partiesModal/PartiesModal";
import { getLabelFromDictionary } from "../../../config/dictionary";
import ExpedientActions from "./components/expedientActions/ExpedientActions";
import GenerateQRModal from "./modals/GenerateQRModal";
import { UserEntity } from "../../../shared/entities/user.entity";
import documentTypeConfigEvidenceList from "./config/documentTypeConfigEvidenceList";

const stylesFormatted = {
  ...selectStylesDefault,
  menu: (provided) => ({
    ...provided,
    zIndex: 100
  })
};

const selectStylesError = {
  control: (provided,) => {
    return {
      ...provided,
      border: '1px solid red'
    };
  }
};

const activateMultisignatureByDocumentTypeList = ['privateJudgment', 'agreement'];

const ExpedientDetails = (props) => {
  const specialPermissions = Cookies.getJSON('special_permissions_03');
  const canAssignUsers = specialPermissions && specialPermissions[0] && specialPermissions[0]['assign_users'] === '1';
  const canSign = specialPermissions && specialPermissions[0] && specialPermissions[0]['sign'] === '1';
  const canDownloadExpedient = specialPermissions && specialPermissions[0] && specialPermissions[0]['download_expedient'] === '1';
  const canUseExpedientIndicators = specialPermissions && specialPermissions[0] && specialPermissions[0]['expedient_indicators'] === '1';
  const canUseEditParties = specialPermissions && specialPermissions[0] && specialPermissions[0]['create_edit_parts'] === '1';
  const [isSimpleSignatureAlertShowing, setIsSimpleSignatureAlertShowing] = useState(false);
  const isPermissionUploadDocumentsWithoutSignature = useMemo(() => UserEntity.validatePermissions('documents_without_sing'), []);
  const [dataExpedient, setDataExpedient] = useState({});
  const [expedientParts, setExpedientParts] = useState([]);
  const [expedientParties, setExpedientParties] = useState([]);
  const [search, setSearch] = useState("");
  const [enableSaveBtn, setEnableSaveBtn] = useState(true);
  const [textNotDataDocuments, setTextNotDataDocuments] = useState("");
  const [show, setShow] = useState(false);
  const [typeFile, setTypeFile] = useState({});
  const [file, setFile] = useState({});
  const [imageURL, setImageURL] = useState("");
  const [errors, setErrors] = useState({});
  const [dataDocuments, setDataDocuments] = useState([]);
  const loader = useContext(LoaderContext);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [promotions, setPromotions] = useState([]);
  const { authentication_token_03 } = Cookies.get();
  const [showDocuments, setShowDocuments] = useState(false);
  const [showRelationDocuments, setShowRelationDocuments] = useState(false);
  const [showExpirationDocuments, setShowExpirationDocuments] = useState(false);
  const [documentsState, setDocumentsData] = useState([]);
  const [dataUploadEvidences, setDataUploadEvidences] = useState(null);
  const [agreementTypes, setAgreementTypes] = useState([]);
  const [showModalTemplates, setShowModalTemplates] = useState(false);
  const [templatesType, setTemplatesType] = useState('');
  const [reloadDataTemplates, setReloadDataTemplates] = useState(false);
  const [showDocumentsView, setShowDocumentsView] = useState(false);
  const [documentsView, setDocumentsView] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(0);
  const [pauseCarousel, setPauseCarousel] = useState(false);
  const isInternalTurningByDefault = useRef(false);
  const hasAgreementWithoutAnsweringPromotionModalBeenAccepted = useRef(false);
  const [emailsOptions, setEmailsOptions] = useState([]);
  const [showModalNotifications, setShowModalNotifications] = useState(false);
  const [selectedPromotions, setSelectedPromotions] = useState([]);
  const [selectedAgreementTypes, setSelectedAgreementTypes] = useState([]);
  const [searching, setSearching] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [isUsersAndPermissionsListModalShowing, setIsUsersAndPermissionsListModalShowing] = useState(false);
  const [isGenerateQRModalShowing, setIsGenerateQRModalShowing] = useState(false);
  // For comment modal.
  const [commentModal, setCommentModal] = useState('');
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [currentDocumentFromDocumentExpedientTable, setCurrentDocumentFromDocumentExpedientTable] = useState('');
  
  // For modal assign users.
  const [usersFound, setUsersFound] = useState([]);
  const [assignUserSearch, setAssignUserSearch] = useState([]);
  const [usersWithPermission, setUsersWithPermission] = useState([]);
  const [showModalAssignUsers, setShowModalAssignUsers] = useState(false);

  const [showModalParties, setShowModalParties] = useState(false);
  const [loadUploadAnex, setLoadUploadAnex] = useState(false);
  const expedientNumber = _.get(dataExpedient, 'expedient_number', '');
  const expedientId = props.match.params.expedientId;

  const {
    handleSaveSignatures,
    handleRemoveSavedSignatures,
    handleResetElectronicSignaturesPendingToSave,
  } = useMultipleElectronicSignatureModalContext();

  const {
    onUpdateExpedientDetailsComponetRef,
  } = useExpedientDetailsContext();

  const expedientTableDocuments = dataDocuments.filter((document) => {
    const isDocumentVisible = document.isVisible;
    const doesIsDocumentVisibleExist = typeof isDocumentVisible === 'boolean';
    if (doesIsDocumentVisibleExist) {
      return isDocumentVisible;
    }
    return true
  });

  // Form modal notifications.
  const [showModalSendNotifications, setShowModalSendNotifications] = useState(false);
  const [isPublicJudgmentAlertModalShowing, setIsPublicJudgmentAlertModalShowing] = useState(false);
  const [checked_agreement, setCheckAgreement] = useState(false);
  const [checked_judgment, setCheckJudgment] = useState(false);
  const [currentDocumentType, setCurrentDocumentType] = useState({});
  const doesCurrentDocumentTypeExist = Boolean(Object.keys(currentDocumentType).length);
  const notificationType = useMemo(() => {
    if (checked_judgment)
      return 'judgment';
    if (checked_agreement)
      return 'agreement';
    return undefined;
  }, [checked_judgment, checked_agreement]);

  const [agreementsOrJudgmentsToNotifyOptions, setAgreementsOrJudgmentsToNotifyOptions] = useState([]);
  const [selectedAgreementsOrJudgmentsToNotify, setSelectedAgreementsOrJudgmentsToNotify] = useState([]);

  const aDocumentIsBeingSigned = useRef(false);

  const isBulletinEnabled = useMemo(() => currentDocumentType.isBulletin, [currentDocumentType]);
  const isMultisignatureDisabled = useMemo(() => !currentDocumentType.isMultipleSignable, [currentDocumentType]);
  const canDocumentBeSigned = useMemo(() => currentDocumentType.isSingleSignable, [currentDocumentType]);

  const isExpedientJudgmentActive = (Boolean(dataExpedient) && Boolean(dataExpedient.judgment_active));

  const transferState = useTransferState({
    handleSaveSignatures,
    handleRemoveSavedSignatures,
    isInternalTurningByDefault: isInternalTurningByDefault.current,
    history: props.history,
    expedientData: dataExpedient,
    onCloseTransferModalClick: setCloseModal,
  });

  const documentTypesHiddenList = {
    privateJudgment: {
      isHidden: !isExpedientJudgmentActive
    },
    publicJudgment: {
      isHidden: !isExpedientJudgmentActive
    }
  }

  const [isIndicatorsModalShowing, setIsIndicatorsModalShowing] = useState(false);
  const documentTypes = getFilteredDocumentTypes(documentTypesHiddenList);

  const {
    isATurnedExpedient,
    setIsATurnedExpedient,
  } = useCommentsModalContext();

  useEffect(() => {
    (async function () {
      loader.show(true);
      await getDocumentsByExpedientNumber(
        expedientId,
        setDataExpedient,
        setDataDocuments,
        setTextNotDataDocuments,
        setTotalDocuments,
        sizePerPage,
        page,
        loader,
        setStateDocuments,
        setCommentModal,
        setShowCommentModal,
        onManageViewingPermissionsButtonClick,
        setExpedientParts,
      );
      loader.show(false);
    })();
    setPartsExpedient();
  }, []);

  useEffect(() => {
    const getAndSetAgreementsOrJudgmentsToNotifyOptions = async () => {
      setFile({ ...file, agreementsOrJudgmentsToNotify: [] })
      setSelectedAgreementsOrJudgmentsToNotify([]);
      if (checked_agreement) {
        loader.show(true);
        const _agreementsOrJudgmentsToNotifyOptions = await getAgreementsToNotifyByExpedientId(expedientId, notificationType);
        setAgreementsOrJudgmentsToNotifyOptions(_agreementsOrJudgmentsToNotifyOptions);
        loader.show(false);
      }
    }
    if (notificationType) {
      getAndSetAgreementsOrJudgmentsToNotifyOptions();
    }
  }, [notificationType])

  useEffect(() => {
    setFile({ ...file, excerpt: '' });
    if (!isBulletinEnabled) {
      setFile({ ...file, isPublishedInBulletin: 'no' });
    } else {
      setFile({ ...file, isPublishedInBulletin: '' });
    }
  }, [file.document_type])

  useEffect(() => {
    if (isMultisignatureDisabled) {
      setIsMultipleSignature(false);
    }
  }, [isMultisignatureDisabled])

  const handleGetDocumentsByExpedientNumber = async () => {
    await getDocumentsByExpedientNumber(
      expedientId,
      setDataExpedient,
      setDataDocuments,
      setTextNotDataDocuments,
      setTotalDocuments,
      sizePerPage,
      page,
      loader,
      setStateDocuments,
      setCommentModal,
      setShowCommentModal,
      onManageViewingPermissionsButtonClick,
      setExpedientParts,
    );
  }

  onUpdateExpedientDetailsComponetRef.current = handleGetDocumentsByExpedientNumber;

  useEffect(() => {
    setIsATurnedExpedient(dataExpedient.hasOwnProperty('turned') ? dataExpedient.turned : false)
  }, [dataExpedient])  

  const {
    isElectronicSignatureModalSowing,
    setIsElectronicSignatureModalSowing,
    electronicSignaturesList,
    onCloseModal: onCloseCredentialsModal,
    onAddElectronicSignature,
    isMultipleSignature,
    setIsMultipleSignature,
    getPkcs7,
    isReadyToCreatePkcs7,
    documentToSign,
    onAddDocumentToSign,
    onDelteElectronicSignatureById,
    singleElectronicSignature,
    isThereASignature,
    onResetSignatures,
  } = useElectronicSignatureProcessContext();

  const { getTableDataAndSetInTable: getNewRecordsFromFilesPendingSignatureTable } = useFilesPendingSignatureContext();
 
  const setPartsExpedient = async () => {
    setExpedientParts( await getExpedientParts(expedientId,setExpedientParties));
  }

  const signDocument = async () => {
    setEnableSaveBtn(false);
    try {
      let isFileLoaded = false;
      const areTherePromotions = file.all_promotion;
      if (areTherePromotions || hasAgreementWithoutAnsweringPromotionModalBeenAccepted.current) {
        if (aDocumentIsBeingSigned.current) {
          return
        }
        aDocumentIsBeingSigned.current = true;
        isFileLoaded = await sendFile();
      } else {
        setShowModalConfirm(true);
      }
      hasAgreementWithoutAnsweringPromotionModalBeenAccepted.current = false;
      if (isMultipleSignature && isFileLoaded) {
        isInternalTurningByDefault.current = true;
        transferState.handleOpenTransferModal();
      }
    } catch (error) {
      console.error(error);
    } finally {
      aDocumentIsBeingSigned.current = false;
      setEnableSaveBtn(true);
    }
  }

  const handleActivateMultisignatureByDocumentType = (documentType) => {
    const isADocumentTypeToActivateMultisignature = activateMultisignatureByDocumentTypeList.includes(documentType);
    if (isADocumentTypeToActivateMultisignature) {
      setIsMultipleSignature(true);
      return
    }
    setIsMultipleSignature(false);
  }
  
  const handleToggleMutisignatureButtonClick = ({ target = {} } = {}) => {
    const isADocumentTypeToActivateMultisignature = activateMultisignatureByDocumentTypeList.includes(file.document_type);
    const isChecked = target.checked;

    if (!isADocumentTypeToActivateMultisignature || isChecked) {
      setIsMultipleSignature(isChecked);
      return
    }

    setIsSimpleSignatureAlertShowing(true);
  }

  const onContinueSimpleSignatureAlertClick = () => {
    setIsMultipleSignature(false);
    setIsSimpleSignatureAlertShowing(false);
  }

  const onCancelSimpleSignatureAlertClick = () => {
    setIsSimpleSignatureAlertShowing(false);
  }

  function onManageViewingPermissionsButtonClick(_documentFromDocumentExpedientTable) {
    setIsUsersAndPermissionsListModalShowing(true);
    setCurrentDocumentFromDocumentExpedientTable(_documentFromDocumentExpedientTable);
  }

  const onClickNotificationModalAcceptButton = async ({ users }) => {
    loader.show(true);
    const document = await saveFile();
    if (document) {
      await handleRequestSendDataNotificationEmail({
        expedient_id: expedientId,
        notification_template_id: file.email,
        emails: users,
        using_document_expedient: 'si',
        document_expedient_id: document.id,
        checked_judgment: checked_judgment,
        checked_agreement: checked_agreement
      });
      await reloadComponent();
    }
    setShowModalSendNotifications(false);
    loader.show(false);
  }

  const setStateDocuments = async (documentId, isNotification) => {
    loader.show(true);
    if (Array.isArray(documentId)) {
      setDocumentsData(documentId);
      if (isNotification == true) {
        setShowExpirationDocuments(true);
      } else {
        setShowRelationDocuments(true);
      }
    } else {
      if (isNotification) {
        await getEvidencesDocument(
          documentId,
          setDocumentsData,
          setShowModalNotifications
        );
      } else {
        await getEvidencesDocument(
          documentId,
          setDocumentsData,
          setShowDocuments
        );
      }
    }

    loader.show(false);
  };

  const handleShowDocumentsView = async () => {
    loader.show(true);
    await getAllDocumentsOfExpedient(
      expedientId, setDocumentsView, setShowDocumentsView
    );
    loader.show(false);
  };

  const setCloseDocumentsView = () => {
    setCurrentDocument(0);
    setPauseCarousel(true);
    setShowDocumentsView(false);
  };

  async function reloadComponent(message, title, time) {
    loader.show(true);
    await getDocumentsByExpedientNumber(
      expedientId,
      setDataExpedient,
      setDataDocuments,
      setTextNotDataDocuments,
      setTotalDocuments,
      sizePerPage,
      page,
      loader,
      setStateDocuments,
      setCommentModal,
      setShowCommentModal,
      onManageViewingPermissionsButtonClick,
      setExpedientParts,
    );
    loader.show(false);
    setShowCommentModal(false);

    const isFileLoaded = !message;
    setCloseModal();
    loader.show(false);
    NotificationManager.success(
      isFileLoaded ? "El archivo fue cargado correctamente" : message,
      title ? title : "",
      time ? time : 3000
    );

    return isFileLoaded
  };

  const onChangeHandlerAgreement = () => {
    setCheckJudgment(false);
    setCheckAgreement(true);
  };

  const onChangeHandlerJudgment = () => {
    setCheckJudgment(true);
    setCheckAgreement(false);

  };

  const getLabelDocumentsAccept = () => {
    if(isPermissionUploadDocumentsWithoutSignature) {
      return 'pdf';
    } else {
      return file.document_type !== 'publicJudgment' ? 'pdf, doc o docx' : 'pdf'
    }
  };

  const getTypeDocumentAccepted = () => {
    if(isPermissionUploadDocumentsWithoutSignature) {
      return '.pdf';
    } else {
      return currentDocumentType.value === "publicJudgment" ? ".pdf" : ".pdf, .doc, .docx"
    }
  }

  const getTypeDocumentAcceptedInDropzone = () => {
    if(isPermissionUploadDocumentsWithoutSignature) {
      return ['.pdf'];
    } else {
      return [".pdf", ".doc", ".docx"]
    }
  }
  
  const isCommentFieldShowing = getIsCommentFieldShowingInFile(file.document_type);

  const isElectronicSignatureTableShowing = (Boolean(electronicSignaturesList.length && isMultipleSignature) || (!isMultipleSignature && Boolean(singleElectronicSignature)));

  const bodyModal = (
    <>
      <Form>
        <Form.Group className="border-bottom border-secondary mb-3 pb-3">
          <Form.Label>* Tipo de documento:</Form.Label>

          <Select
            options={documentTypes}
            onChange={async (selected) => {
              if (selected["value"] === "agreement") {
                loader.show(true);
                const hydratePromotions = (promotionsFormatted) => {
                  setPromotions(promotionsFormatted);
                  setSelectedPromotions(promotionsFormatted);
                };
                await getPromotionsWithoutAgreement(
                  expedientId,
                  hydratePromotions
                );
                await getAgreementTypes(
                  setAgreementTypes
                );
                loader.show(false);
              }

              const isANotification = selected.value === 'notification';

              if (isANotification) {
                getEmailsTemplates(expedientId, setEmailsOptions)
              }

              setFile({
                document_type: selected["value"],
                all_promotion: true,
                electronicNotification: isANotification ? 'si' : 'no',
              });

              handleActivateMultisignatureByDocumentType(selected.value);
              setCurrentDocumentType(selected);
              setSelectedAgreementTypes([]);
              setErrors({ ...errors, document_type: [] });
            }}
            placeholder="Selecciona el tipo de documento"
            styles={selectStylesDefault}
            classNamePrefix="select-body-modal"
          />
          {renderErrorsByInputName(errors, "document_type")}
        </Form.Group>

        {
          !isPermissionUploadDocumentsWithoutSignature && canDocumentBeSigned
          &&
          <div className="border-bottom border-secondary mb-3 pb-3">
            <p className="m-0">
              * Firma Electrónica
            </p>

            {
              isElectronicSignatureModalSowing
              &&
              <CredentialsModal
                isModalSowing={isElectronicSignatureModalSowing}
                onCloseModal={onCloseCredentialsModal}
                addElectronicSignatureHandle={onAddElectronicSignature}
                isMultipleSignature={isMultipleSignature}
                electronicSignatureSettings={electronicSignatureSettingsFirel}
                isOnlyOneSignaturePerUserActivated
              />
            }

            <Button
              variant="success"
              onClick={() => setIsElectronicSignatureModalSowing(true)}
              className={'mb-3'}
              style={{ marginTop: '20px' }}
            >
              {isThereASignature ? 'Cambiar Firma' : 'Agregar Firma'}
            </Button>

            {
              (!isMultisignatureDisabled)
              &&
              <section className="text-muted mt-3 mb-3">
                <ToggleButton
                  name='ToggleButtonName'
                  onChange={handleToggleMutisignatureButtonClick}
                  id='isMultipleSignatureToggleButton'
                  isCheckedControlled={isMultipleSignature}
                >
                  Multifirma
                </ToggleButton>
                <div className="text-muted mt-1 mb-2" style={{ fontSize: '11.2px', lineHeight: '1.5' }}>
                  Activa esta sección si deseas que el documento sea firmado por más de un usuario.
                </div>
              </section>
            }

            {
              isElectronicSignatureTableShowing
              &&
              <>
                <p className='m-0'>
                  {isMultipleSignature ? 'Lista de Firmantes' : 'Firmante'}
                </p>
                <ElectronicSignatureTable
                  electronicSignaturesList={electronicSignaturesList}
                  singleElectronicSignature={singleElectronicSignature}
                  isMultipleSignature={isMultipleSignature}
                  onDelteElectronicSignatureById={onDelteElectronicSignatureById}
                />
              </>
            }
          </div>
        }

        {
          (file['document_type'] === 'agreement')

            ?

            <Form.Group>
              <Form.Label>* Rubro</Form.Label>
              <Select
                defaultValue={selectedAgreementTypes}
                onChange={(selected) => {
                  setSelectedAgreementTypes(selected);
                  const agreementsFormatted = selected ? selected.map((p) => p.value) : [];
                  const agreementsFormattedLabel = selected ? selected.map((p) => p.label) : [];
                  setFile({
                    ...file,
                    alias: agreementsFormattedLabel.toString(),
                    headings: agreementsFormatted
                  });
                }}
                value={selectedAgreementTypes}
                isClearable={false}
                isMulti
                options={agreementTypes}
                placeholder="Selecciona rubros"
                className="basic-multi-select"
                classNamePrefix="select"
                noOptionsMessage={agreementTypes.length > 0 ? () => "Sin más opciones disponibles" : () => "Sin opciones disponibles"}
                styles={stylesFormatted}
              />
              {renderErrorsByInputName(errors, "alias")}
            </Form.Group>

            :

            (
              (doesCurrentDocumentTypeExist && currentDocumentType.getKeysDataToSend(file).includes("alias"))
              &&
              <Form.Group>
                <Form.Label>* Etiqueta:</Form.Label>
                <Form.Control
                  onChange={(e) => {
                    setErrors({ ...errors, alias: [] });
                    setFile({ ...file, alias: e.target.value });
                  }}
                  placeholder="Agrega una etiqueta para identificar este documento"
                />
                {renderErrorsByInputName(errors, "alias")}
              </Form.Group>
            )
        }

        {
          (currentDocumentType.isJudgmentType)
          &&
          <Form.Group>
            <Form.Label>* Selecciona el tipo de sentencia</Form.Label>
            <div>
              <Form.Check
                inline
                type={'radio'}
                label={`Interlocutoria`}
                id={`interlocutory`}
                className='pl-3'
                value={'interlocutory'}
                checked={file && file.judgment_type === 'interlocutory'}
                onChange={({ target: { value } }) => setFile({ ...file, judgment_type: value })}
              />
              <Form.Check
                inline
                type={'radio'}
                label={`Definitiva`}
                id={`definitive`}
                value={'definitive'}
                checked={file && file.judgment_type === 'definitive'}
                onChange={({ target: { value } }) => setFile({ ...file, judgment_type: value })}
              />
              <Form.Check
                inline
                type={'radio'}
                label={`Segunda instancia`}
                id={`second_instance_judgment_type_expedient`}
                value={'second_instance'}
                checked={file && file.judgment_type === 'second_instance'}
                onChange={({ target: { value } }) => setFile({ ...file, judgment_type: value })}
              />
            </div>
            {renderErrorsByInputName(errors, "judgment_type")}
          </Form.Group>
        }

        {
          isCommentFieldShowing
          &&
          <Form.Group>
            <Form.Label>Comentarios:</Form.Label>
            <Form.Control
              onChange={(e) => setFile({ ...file, comment: e.target.value })}
              as="textarea"
              rows="5"
              aria-label="Comentarios"
            />
            {renderErrorsByInputName(errors, "comment")}
          </Form.Group>
        }

        {
          isBulletinEnabled
          &&
          <>
            <Form.Group>
              <Form.Label>* ¿Desea publicar en el boletín?</Form.Label>
              <div>
                <Form.Check
                  inline
                  type={'radio'}
                  label={`Sí`}
                  id={`accept-add-bulletin`}
                  className='pl-3'
                  value={'si'}
                  checked={file && file.isPublishedInBulletin === 'si'}
                  onChange={({ target: { value } }) => setFile({ ...file, isPublishedInBulletin: value })}
                />
                <Form.Check
                  inline
                  type={'radio'}
                  label={`No`}
                  id={`deny-add-bulletin`}
                  value={'no'}
                  checked={file && file.isPublishedInBulletin === 'no'}
                  onChange={({ target: { value } }) => {
                    setFile({ ...file, isPublishedInBulletin: value });
                  }}
                />
              </div>
              {renderErrorsByInputName(errors, "isPublishedInBulletin")}
            </Form.Group>

            {
              (file.isPublishedInBulletin && file.isPublishedInBulletin === 'si')
              &&
              <>
                { file["document_type"] === "publicJudgment" &&
                  <BulletinFields 
                    onChange={setFile}
                    values={file}
                    errors={errors}
                    renderErrorsByInputName={renderErrorsByInputName}
                  />
                }
                                                               
                <Form.Group>
                  <Form.Label>* Extracto para boletín:</Form.Label>
                  <Form.Control
                    onChange={({ target: { value } }) => setFile({ ...file, excerpt: value })}
                    as="textarea"
                    rows="5"
                    aria-label="Extracto para boletín"
                  />
                  {renderErrorsByInputName(errors, "excerpt")}
                </Form.Group>
              </>
            }
            <br />
          </>
        }

        {
          file["document_type"] ?
            <>
              <Form.Group controlId="formBasicFile">
                <TextTooltip text={'Evitar archivos con caracteres especiales: puntos, guion bajo, barra inclinada, dos puntos, signos etc.'}>
                  <Form.Label>{'Documento:'}</Form.Label>
                </TextTooltip>
                <Dropzone
                  onDrop={(files, element) => onDrop(files, 1)}
                  accept={getTypeDocumentAcceptedInDropzone()}
                >
                  {({ getRootProps, getInputProps }) => (
                    <TextTooltip
                      className={'d-block'}
                      text={'El documento a cargar, no deberá superar los 18MB de tamaño'}
                    >
                      <div className="file-nilo-wrapper" {...getRootProps()}>
                        {'* Selecciona un documento'}
                        <input id="fileInputNewDocuemnt" style={{ display: "inline!important" }} className="form-control" {...getInputProps({
                          accept: getTypeDocumentAccepted(),
                        })} />
                      </div>
                    </TextTooltip>
                  )}
                </Dropzone>
              </Form.Group>
              <div className="classHelperContextual" >
                <small>El documento debe ser de tipo {getLabelDocumentsAccept()}</small>
              </div>

              {renderErrorsByInputName(errors, "file")}
              {renderErrorsByInputName(errors, "extension")}

              <div
                style={{ marginTop: '10px' }}
                className={typeFile["type"] ? "file-upload-wrapper" : "d-none"}
              >
                <img
                  className={typeFile["type"] === "document" ? "d-block" : "d-none"}
                  src={process.env.PUBLIC_URL + "/img/document.svg"}
                  alt="Documento"
                />
                <img
                  className={typeFile["type"] === "image" ? "" : "d-none"}
                  src={imageURL}
                  alt="Imagen"
                  width="50%"
                />
              </div>
              <small style={{ float: "right" }}>{typeFile["name"]}</small>
              <br />
            </> : ""
        }

        {
          file["document_type"] === "promotion" || file['document_type'] === 'demand' || file['document_type'] === 'writ' && dataExpedient['isPenal'] === true ?
            <>
              <Form.Group>
                <br />
                <Form.Label>* Nombre del promovente:</Form.Label>
                <Form.Control
                  value={file["promoter"] ? file["promoter"] : ""}
                  aria-label="Nombre del promovente"
                  onChange={(e) => {
                    setFile({ ...file, promoter: e.target.value });
                    setErrors({ ...errors, promoter: [] });
                  }}
                  type="text"
                />
                {renderErrorsByInputName(errors, "promoter")}
              </Form.Group>

              <Form.Group >
                <Form.Label>* ¿Quieres agregar anexos?</Form.Label>
                <Form.Check
                  type="radio"
                  label={"Si"}
                  checked={file['hasProofs'] === "si" ? true : false}
                  id={"si"}
                  value="si"
                  onChange={(e) => {
                    setFile({ ...file, hasProofs: e.target.value });
                  }}
                />
                <Form.Check
                  type="radio"
                  label={"No"}
                  checked={file['hasProofs'] === "no" ? true : false}
                  id={"no"}
                  value="no"
                  onChange={(e) => {
                    setFile({ ...file, hasProofs: e.target.value });
                  }}
                />
                {renderErrorsByInputName(errors, "hasProofs")}
              </Form.Group>
            </>
            : file["document_type"] === "agreement" ?
              <>
                <Form.Group>
                  <br />
                  <Form.Label>Respuesta a promociones:</Form.Label>

                  <Select
                    value={selectedPromotions}
                    isClearable={false}
                    isMulti
                    options={promotions}
                    placeholder="Selecciona las promociones de este acuerdo"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    noOptionsMessage={promotions.length > 0 ? () => "Sin más opciones disponibles" : () => "Sin opciones disponibles"}
                    styles={selectStylesDefault}
                    onChange={(selected) => {
                      setSelectedPromotions(selected);
                      setFile({
                        ...file,
                        selectedPromotions: selected,
                        all_promotion: selected ? selected.length === promotions.length : false
                      });
                    }}
                  />
                  {renderErrorsByInputName(errors, "selectedPromotions")}
                </Form.Group>
              </> : ""
        }

        {
          file['document_type'] === 'promotion' && file['hasProofs'] === 'si' || file['document_type'] === 'demand' && file['hasProofs'] === 'si'  || file['document_type'] === 'writ' && file['hasProofs'] === 'si' ?
            <>
              <Form.Group controlId="formBasicFile">
                <Form.Label>* Anexos</Form.Label>
                <TableUploadManyFiles
                  acceptFiles={[".png", ".pdf", ".jpg", ".jpeg"]}
                  getFiles={(files) => {
                    if (files.length > 0) {
                      setFile({
                        ...file,
                        evidence_file: files
                      });
                      setErrors({
                        ...errors,
                        evidence_file: []
                      });
                    }
                    else {
                      delete file['evidence_file'];
                    }
                  }}
                  reloadDataComponent={reloadComponent}
                  dataUploadEvidences={dataUploadEvidences}
                  expedientId={expedientId}
                  errorsHeredated={errors}
                  isMultiSignature={isMultipleSignature}
                  loader={loader}
                  setLoadUploadAnex={setLoadUploadAnex}
                />
              </Form.Group>
            </> : ""
        }

        {
          file['document_type'] === 'notification'
          &&
          <>
            <br />
            <Form.Group>
              <Form.Label>¿Desea hacer una notificación electrónica?</Form.Label>
              <Form.Check
                type="checkbox"
                label={"Si"}
                checked={file.electronicNotification === 'si'}
                id={"electronicNotification-check"}
                value={file.electronicNotification === 'si' ? 'no' : 'si'}
                onChange={async (e) => {
                  setFile({ ...file, electronicNotification: e.target.value });
                }}
              />
              {renderErrorsByInputName(errors, "electronicNotification")}
            </Form.Group>

            {file['electronicNotification'] === 'si' ?
              <>
                <Form.Group>
                  <Form.Label>* Plantillas de correo electrónico</Form.Label>
                  <Select
                    options={emailsOptions}
                    onChange={(selected) => {
                      delete errors['email'];
                      setFile({ ...file, email: selected.value });
                    }}
                    placeholder="Selecciona la plantilla para notificar vía correo electrónico"
                    styles={errors['email'] ? selectStylesError : stylesFormatted}
                    noOptionsMessage={emailsOptions.length > 0 ? () => "Sin opciones disponibles" : () => "Da de alta primero plantillas de correo"}
                  />

                  {renderErrorsByInputName(errors, 'email')}
                </Form.Group>
              </> : ''}

            <br />
            <Form.Group>
              <Form.Label>* Tipo de Notificación</Form.Label>
              <Form.Check
                type="radio"
                label={"Acuerdo"}
                id={"check_agreement"}
                value="agreement"
                checked={checked_agreement}
                onChange={onChangeHandlerAgreement}
              />
              <Form.Check
                type="radio"
                label={"Sentencia"}
                id={"check_judgment"}
                value="judgment"
                checked={checked_judgment}
                onChange={onChangeHandlerJudgment}
              />
              {renderErrorsByInputName(errors, "notificationType")}
            </Form.Group>
            <br />
          </>
        }

        {
          ((file["document_type"] === "notification") && checked_agreement)
          &&
          <>
            <Form.Group>
              <Form.Label>* {checked_agreement ? 'Acuerdos' : 'Sentecias'} a notificar:</Form.Label>
              <Select
                value={selectedAgreementsOrJudgmentsToNotify}
                isClearable={false}
                isMulti
                options={agreementsOrJudgmentsToNotifyOptions}
                placeholder={`Selecciona ${checked_agreement ? 'los acuerdos a los' : 'las sentencias a las'} cuales quieres notificar`}
                className="basic-multi-select"
                classNamePrefix="select"
                noOptionsMessage={agreementsOrJudgmentsToNotifyOptions.length > 0 ? () => "Sin más opciones disponibles" : () => "Sin opciones disponibles"}
                styles={selectStylesDefault}
                onChange={(selected) => {
                  setFile({ ...file, agreementsOrJudgmentsToNotify: getStringIdFromASelectArray(selected) })
                  setSelectedAgreementsOrJudgmentsToNotify(selected)
                }}
              />
              {renderErrorsByInputName(errors, "agreementsOrJudgmentsToNotify")}
            </Form.Group>
            <br />
          </>
        }

      </Form>
    </>
  );

  const closeModalDocuments = () => {
    setDocumentsData([]);
    setShowDocuments(false);
  };

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);

    loader.show(true);
    if (searching) {
      await searchDocumentsByExpedientNumberAndFilename(
        expedientId,
        setDataExpedient,
        setDataDocuments,
        setTextNotDataDocuments,
        setTotalDocuments,
        sizePerPage,
        page,
        loader,
        setStateDocuments,
        setCommentModal,
        setShowCommentModal,
        search.replace(/^\s+|\s+$/gm, ''),
        onManageViewingPermissionsButtonClick,
        setExpedientParts,
      );
    } else {
      await getDocumentsByExpedientNumber(
        expedientId,
        setDataExpedient,
        setDataDocuments,
        setTextNotDataDocuments,
        setTotalDocuments,
        sizePerPage,
        page,
        loader,
        setStateDocuments,
        setCommentModal,
        setShowCommentModal,
        onManageViewingPermissionsButtonClick,
        setExpedientParts,
      );
    }
    loader.show(false);
  };

  const searchDocuments = async () => {
    if (search.length > 0 && /^$|.*\S+.*/.test(search)) {
      setSearching(true);
      loader.show(true);
      await searchDocumentsByExpedientNumberAndFilename(
        expedientId,
        setDataExpedient,
        setDataDocuments,
        setTextNotDataDocuments,
        setTotalDocuments,
        10,
        1,
        loader,
        setStateDocuments,
        setCommentModal,
        setShowCommentModal,
        search.replace(/^\s+|\s+$/gm, ''),
        onManageViewingPermissionsButtonClick,
        setExpedientParts,
      );
      loader.show(false);
    }
  };

  const handleUploadEvidences = (_file, _responseDocumentSave, isMultisignature = false) => {
    const documentTypeConfigEvidence = documentTypeConfigEvidenceList[_file.document_type];
    const isAValidEvidence = _file.documentType === 'promotion' ? Boolean(_.get(_responseDocumentSave, 'data.data.promotion_id')) : true;
    if(!documentTypeConfigEvidence) {
      return
    }
    const documentResponsePropertyName = isMultisignature ? 'signatureDocument' : 'documentExpedient';

    const isAValidLoadOfEvidence = (
      _file &&
      _file.document_type === documentTypeConfigEvidence.documentType &&
      _file.hasProofs === 'si' &&
      _responseDocumentSave &&
      _responseDocumentSave.data &&
      _responseDocumentSave.data.data &&
      _responseDocumentSave.data.data[documentResponsePropertyName] &&
      (typeof _responseDocumentSave.data.data[documentResponsePropertyName] === 'object') &&
      isAValidEvidence
    );

    if (!isAValidLoadOfEvidence) {
      return
    }

    const documentResponse = _responseDocumentSave.data.data[documentResponsePropertyName];

    setDataUploadEvidences({
      document_type: documentResponse.document_type,
      [documentTypeConfigEvidence.nameId]: _responseDocumentSave.data.data[documentTypeConfigEvidence.nameId],
      documentUpload: documentResponse,
      change: new Date().getTime(),
    });
  }

  const cleanSearchDocuments = async () => {
    setSearch('');
    setSearching(false);
    loader.show(true);
    await handleGetDocumentsByExpedientNumber();
    loader.show(false);
  };

  const showModalNewDocument = () => {
    setCloseModal();
    setFile({
      promoter: dataExpedient['actor'] || 'N/A'
    });
    setShow(true);
    setIsMultipleSignature(false);
  };

  function setCloseModal() {
    setDataUploadEvidences(null);
    setErrors({});
    setFile({});
    setTypeFile({});
    setImageURL("");
    setSelectedPromotions([]);
    setShow(false);
    onResetSignatures();
    handleResetElectronicSignaturesPendingToSave();
    setSelectedAgreementsOrJudgmentsToNotify([]);
    setAgreementsOrJudgmentsToNotifyOptions([]);
    setCheckJudgment(false);
    setCheckAgreement(false);
  };

  const onDrop = (files, inputNumber) => {

    const droppedFile = files[0];
    const doesDocumentExist = Boolean(droppedFile);
    if (!doesDocumentExist) {
      showAlertForDocumentToBeSignedNotAllowed();
      return
    }

    const canTypeOfDocumentBeSigned = getCanTypeOfDocumentBeSigned(droppedFile.type);
    if (!canTypeOfDocumentBeSigned) {
      showAlertForDocumentToBeSignedNotAllowed();
      return
    }

    const isAValidMaxFileSize = getIsAValidMaxFileSize(droppedFile);
    if (!isAValidMaxFileSize) {
      NotificationManager.error("El archivo que intenta cargar al sistema, supera los 18MB de tamaño permitido. Intente con un documento de menor tamaño");
      return
    }

    const fileToSave = getFileWithExtensionInLowercase(droppedFile);
    
    onAddDocumentToSign([fileToSave]);
    const reader = new FileReader();

    if (doesDocumentExist && inputNumber === 1) {

      if (fileToSave["type"].includes("image")) {
        setImageURL(URL.createObjectURL(fileToSave));
        setTypeFile({ type: "image", name: fileToSave["name"] });
      }
      else setTypeFile({ type: "document", name: fileToSave["name"] });

      reader.onload = (event) => {

        setErrors({ ...errors, file: [] });
        setFile({ ...file, file: fileToSave });
      };

      reader.readAsDataURL(fileToSave);
    }
    else if (doesDocumentExist && inputNumber === 2) {

      reader.onload = (event) => {

        setErrors({ ...errors, evidence_file: [] });
        setFile({ ...file, evidence_file: fileToSave });
      };

      reader.readAsDataURL(fileToSave);
    }
  };

  const getRequestDataWithElectronicSignaturePropertiesMerged = async (requestData) => {
    const argumentsForGetElectronicSignatureProperties = {
      isMultipleSignature,
      isReadyToCreatePkcs7,
      getPkcs7,
      documentToSignName: documentToSign.name,
      shouldPkcs7BeGenerated: !isMultipleSignature,
    };

    const electronicSignatureProperties = await getElectronicSignatureProperties(argumentsForGetElectronicSignatureProperties);
    if (!_.isObject(electronicSignatureProperties)) {
      throw new Error('Errores en firma electronica');
    }

    const electronicSignaturePropsFormData = objectToFormData(electronicSignatureProperties);
    const requestDataResult = mergeFormData(requestData, electronicSignaturePropsFormData);
    return requestDataResult
  }

  const saveFile = async () => {
    let isFileLoaded = false;
    loader.show(true);
    // PRIMERO ENVIAMOS Y CREAMOS EL DOCUMENTO
    let formData = new FormData();
    formData.append('expedient_id', expedientId);

    const keysDataSend = file["document_type"] === "promotion" && file["hasProofs"] === "no" ?
      ["alias", "document_type", "file", "promoter", "amount_copies", "amount_signatures", "hasProofs", "expedient_number", "comment"] :
      file["document_type"] === "promotion" && file["hasProofs"] === "si" ?
        ["alias", "document_type", "file", "promoter", "amount_copies", "amount_signatures", "hasProofs", "expedient_number", "comment"] :
        file["document_type"] === "judgment" ?
          ["document_type", "file", "comment", "expedient_number"] :
          file["document_type"] === "notification" && file["electronicNotification"] === "si" ?
            ["document_type", "file", "email", "comment", "expedient_number", "check_agreement", "check_judgment"] :
            file["document_type"] === "notification" && file["electronicNotification"] !== "si" ?
              ["document_type", "file", "comment", "expedient_number", "check_agreement", "check_judgment"] :
              file["document_type"] === "agreement" ?
                ["alias", "document_type", "file", "comment", "expedient_number", "selectedPromotions"] :
                ["alias", "document_type", "file", "comment", "expedient_number"];

    if (file.document_type === "notification") {
      file.notificationType = notificationType;
      keysDataSend.push('notificationType');
      if (checked_agreement) {
        keysDataSend.push('agreementsOrJudgmentsToNotify');
      }
    }

    for (const dataKey of keysDataSend) {
      if (dataKey === 'expedient_number') formData.append('expedient_number', expedientNumber.replace(/-/g, '/'));
      else if (dataKey === 'amount_copies') formData.append('amount_copies', 0);
      else if (dataKey === 'amount_signatures') formData.append('amount_copies', 0);
      else if (dataKey === 'selectedPromotions') selectedPromotions
        ? formData.set('selectedPromotions', selectedPromotions.map(e => e.value))
        : formData.set('selectedPromotions', '');
      else {
        if (dataKey == 'check_agreement') formData.append('check_agreement', checked_agreement);
        else if (dataKey == 'check_judgment') formData.append('check_judgment', checked_judgment);
        else formData.append(dataKey, file[dataKey] ? file[dataKey] : "");
      }
    };

    
    if(!isPermissionUploadDocumentsWithoutSignature) {
      if (canDocumentBeSigned && isMultipleSignature) {
        try {
          const documentWithMultisignature = await handleMultipleSignatureExpedient(formData);
          isFileLoaded = Boolean(documentWithMultisignature);
          loader.show(false);
          return documentWithMultisignature;
        } catch (error) {
          NotificationManager.error(error.message);
          isFileLoaded = false;
          loader.show(false);
          return
        }
      }

      else if (canDocumentBeSigned) {
        try {
          formData = await getRequestDataWithElectronicSignaturePropertiesMerged(formData);
        } catch (error) {
          NotificationManager.error(error.message);
          loader.show(false);
          return
        }
      }

    }

    if (dataUploadEvidences === null) {
      const responseDocumentSave = await axios({
        method: "post",
        url: `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/upload`,
        data: formData,
        headers: {
          Authorization: String(authentication_token_03)
        }
      });
      // DOCUMENTO TIPO PROMOCION CON PRUEBAS
      if (
        file['document_type'] === 'promotion' &&
        file['hasProofs'] === 'si' &&
        responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
        responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
        typeof responseDocumentSave.data.data.documentExpedient === 'object' &&
        responseDocumentSave.data.data.promotion_id
      ) {
        setDataUploadEvidences({
          promotion_id: responseDocumentSave.data.data.promotion_id,
          documentUpload: responseDocumentSave.data.data.documentExpedient,
          change: new Date().getTime()
        });
      }
      //DOCUMENTO TIPO PROMOCION SIN PRUEBAS
      else if (
        file['document_type'] === 'promotion' &&
        file['hasProofs'] === 'no' &&
        responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
        responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
        typeof responseDocumentSave.data.data.documentExpedient === 'object'
      ) {
        isFileLoaded = await reloadComponent();
      }
      // DOCUMENTO TIPO ACUERDO
      else if (
        (file['document_type'] === 'agreement' ||
          file['document_type'] === 'publicJudgment' ||
          file['document_type'] === 'privateJudgment' ||
          (file['document_type'] === 'notification' &&
            file['electronicNotification'] !== 'si')) &&
        responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
        responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
        typeof responseDocumentSave.data.data.documentExpedient === 'object'
      ) {
        isFileLoaded = await reloadComponent();
      }
      else if (
        file['document_type'] === 'notification' &&
        responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
        responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
        typeof responseDocumentSave.data.data.documentExpedient === 'object'
      ) {
        return responseDocumentSave.data.data.documentExpedient;
      }
      // ERRORS
      else {
        if (
          responseDocumentSave.data.data.error &&
          responseDocumentSave.data.data.message &&
          typeof responseDocumentSave.data.data.message === "object"
        ) {
          const errorMessageObject = responseDocumentSave.data.data.message;
          setErrors(responseDocumentSave.data.data.message);
          for (const errorItem in errorMessageObject) {
            NotificationManager.error(errorMessageObject[errorItem]);
          }
        }
        if (typeof responseDocumentSave.data.data.message === 'string')
          NotificationManager.error(responseDocumentSave.data.data.message);
      }
    }
    else {
      let newDataUploadEvidence = { ...dataUploadEvidences };
      newDataUploadEvidence['change'] = new Date().getTime();
      setDataUploadEvidences(newDataUploadEvidence);
    }
    loader.show(false);
    return isFileLoaded;
  }

  const sendFile = async () => {
    setEnableSaveBtn(false);
    let isFileLoaded = false;

    const requiredFields = currentDocumentType.getRequiredFields(file);

    const bulletinValidation = (file.isPublishedInBulletin && file.isPublishedInBulletin === 'si') ? ['isPublishedInBulletin', 'excerpt'] : ['isPublishedInBulletin'];
    const newFieldsBulletin = ['judgeName', 'agreementsSecretary', 'sentenceDate'];
    requiredFields.push(...bulletinValidation);
    if( file.isPublishedInBulletin && file.isPublishedInBulletin === 'si' && file["document_type"] === "publicJudgment" ) requiredFields.push(...newFieldsBulletin);
    if (currentDocumentType.isJudgmentType)
      requiredFields.push('judgment_type');

    file["promotions"] = promotions.length > 0 ? promotions : "";

    if (file.document_type === "notification") {
      file.notificationType = notificationType;
      requiredFields.push('notificationType');
      if (checked_agreement) {
        requiredFields.push('agreementsOrJudgmentsToNotify');
      }
    }

    const responseValidation = fieldsValidation(requiredFields, file);
    
    if (typeof responseValidation === "object") {
      NotificationManager.error("Existen errores que te impiden continuar, por favor revisa los campos marcados con rojo");
      setErrors(responseValidation);
    }
    else {
      loader.show(true);
      // PRIMERO ENVIAMOS Y CREAMOS EL DOCUMENTO
      let formData = new FormData();
      let keysDataSend = currentDocumentType.getKeysDataToSend(file);

      if (file.isPublishedInBulletin === 'si') {
        keysDataSend.push('excerpt')
        keysDataSend.push('isPublishedInBulletin')
        keysDataSend.push('judgeName');
        keysDataSend.push('agreementsSecretary');
        keysDataSend.push('sentenceDate');
        // keysDataSend.push('crimes_id');
        keysDataSend.push('genderPerspective');
        keysDataSend.push('childPerspective');
        keysDataSend.push('womanPerspective');
      }

      formData.append('expedient_id', expedientId);

      for (const dataKey of keysDataSend) {
        if (dataKey === 'expedient_number') formData.append('expedient_number', expedientNumber.replace(/-/g, '/'));
        else if (dataKey === 'selectedPromotions') selectedPromotions
          ? formData.set('selectedPromotions', selectedPromotions.map(e => e.value))
          : formData.set('selectedPromotions', '');
        else if (dataKey === 'headings')
          for (var i = 0; i < file[dataKey].length; i++) {
            formData.append('headings[]', file[dataKey][i]);
          }
        else {
          if (dataKey == 'check_agreement') formData.append('check_agreement', checked_agreement);
          else if (dataKey == 'check_judgment') formData.append('check_judgment', checked_judgment);
          else formData.append(dataKey, file[dataKey] ? file[dataKey] : "");
        }
      };

      if (file.document_type === "notification") {
        formData.append('notificationType', file.notificationType);
        formData.append('agreementsOrJudgmentsToNotify', file.agreementsOrJudgmentsToNotify);
      }

      if (dataUploadEvidences === null) {
        if (file.document_type === 'publicJudgment' && !isPublicJudgmentAlertModalShowing) {
          setIsPublicJudgmentAlertModalShowing(true);
        }
        else if (file.document_type === 'notification' && file.electronicNotification === 'si' && !isMultipleSignature) {
          // Fetch the stakeholders.
          setShowModalSendNotifications(true);
        } else {
          if (file.document_type === 'publicJudgment' && isPublicJudgmentAlertModalShowing) {
            setIsPublicJudgmentAlertModalShowing(false);
          }
          const isPermssionUploadFilesWitoutSignature = UserEntity.validatePermissions('documents_without_sing');
          if(!isPermssionUploadFilesWitoutSignature){ 
            if (canDocumentBeSigned && !isReadyToCreatePkcs7) {
              NotificationManager.error('Debes agregar una firma electrónica');
              loader.show(false);
              return
            }
          }
          if (canDocumentBeSigned && (isPermissionUploadDocumentsWithoutSignature ? false : isMultipleSignature)) {
            try {
              const documentWithMultisignatureResponse = await handleMultipleSignatureExpedient(formData);
              isFileLoaded = Boolean(documentWithMultisignatureResponse);
            } catch (error) {
              NotificationManager.error(error.message);
              isFileLoaded = false;
              loader.show(false);
              return
            } 
            
          } else {
            if(!isPermssionUploadFilesWitoutSignature) {
              if (canDocumentBeSigned) {
                try {
                  formData = await getRequestDataWithElectronicSignaturePropertiesMerged(formData);
                } catch (error) {
                  NotificationManager.error(error.message);
                  loader.show(false);
                  return
                }
              }
            }
            const responseDocumentSave = await axios({
              method: "post",
              url: `${process.env.REACT_APP_URL_API_LARAVEL}/document_expedients/upload`,
              data: formData,
              headers: {
                Authorization: String(authentication_token_03)
              }
            });


            if(responseDocumentSave){
              setEnableSaveBtn(true);
            }
            // DOCUMENTO CON PRUEBAS
            const documentTypeConfigEvidence = documentTypeConfigEvidenceList[file.document_type];
            const canDocumentHaveProofs = Boolean(documentTypeConfigEvidence);

            if (
              canDocumentHaveProofs &&
              file['hasProofs'] === 'si' &&
              responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
              responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
              typeof responseDocumentSave.data.data.documentExpedient === 'object' &&
              responseDocumentSave.data.data[documentTypeConfigEvidence.nameId]
            ) {
              handleUploadEvidences(file, responseDocumentSave);
            }
            //DOCUMENTO TIPO PROMOCION SIN PRUEBAS
            else if (
              file['document_type'] === 'promotion' &&
              file['hasProofs'] === 'no' &&
              responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
              responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
              typeof responseDocumentSave.data.data.documentExpedient === 'object'
            ) {
              isFileLoaded = await reloadComponent();
            }
            //DOCUMENTO TIPO DEMANDA SIN PRUEBAS
            else if (
              file['document_type'] === 'demand' &&
              file['hasProofs'] === 'no' &&
              responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
              responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
              typeof responseDocumentSave.data.data.documentExpedient === 'object'
            ) {
              isFileLoaded = await reloadComponent();
            }
            // DOCUMENTO TIPO ACUERDO
            else if (
              currentDocumentType.isReloadComponentByDefault &&
              responseDocumentSave.data && responseDocumentSave.data.code === 200 &&
              responseDocumentSave.data.data && responseDocumentSave.data.data.documentExpedient &&
              typeof responseDocumentSave.data.data.documentExpedient === 'object'
            ) {
              isFileLoaded = await reloadComponent();
            }
            // ERRORS
            else {
              if (
                responseDocumentSave.data.data.error &&
                responseDocumentSave.data.data.message &&
                typeof responseDocumentSave.data.data.message === "object"
              ) {
                NotificationManager.error('¡Existen errores, revisa los campos marcados en rojo y vuelve a intentarlo!');
                setErrors(responseDocumentSave.data.data.message);
              }

              if (
                responseDocumentSave.data.data.error &&
                responseDocumentSave.data.data.message &&
                typeof responseDocumentSave.data.data.message === "string"
              ) {
                NotificationManager.error(responseDocumentSave.data.data.message);
              }
            }
          }
        }
      } else {
        let newDataUploadEvidence = { ...dataUploadEvidences };
        newDataUploadEvidence['change'] = new Date().getTime();
        setDataUploadEvidences(newDataUploadEvidence);
      }
      handleGetDocumentsByExpedientNumber();
      loader.show(false);
      return isFileLoaded;
    }
  };

  const handleMultipleSignatureExpedient = async (formData) => {
    formData = await getRequestDataWithElectronicSignaturePropertiesMerged(formData);
    const uploadDocumentWithMultisignatureResponse = await onUploadDocumentWithMultisignature(formData);

    const evidencesForPromotionAndDemand = { data: { data: uploadDocumentWithMultisignatureResponse.response } };
    handleUploadEvidences(file, evidencesForPromotionAndDemand, true);

    const documentWithMultisignature = uploadDocumentWithMultisignatureResponse.response.signatureDocument;
    const signatureToSave = electronicSignaturesList[0];
    await onSaveSignatureToTheListOfSignatures(signatureToSave, documentWithMultisignature.id);

    getNewRecordsFromFilesPendingSignatureTable();
    return documentWithMultisignature;
  }

  const footerModal = (
    <div className="w-100 d-flex flex-column align-items-end">
      <div>
        <Button variant="secondary" className="mr-2" onClick={() => setCloseModal()}>Cancelar</Button>
        {
          canDocumentBeSigned
            ?
            <Button 
              variant="primary" 
              disabled = {!enableSaveBtn || loadUploadAnex}
              onClick={signDocument} 
              >
              <i className="fas fa-pen"></i> Firmar
            </Button>
            :
            <Button 
              variant="primary" 
              disabled = {!enableSaveBtn || loadUploadAnex}
              onClick={sendFile}>
              <i className="fas fa-save"></i> Guardar
            </Button>
        }
      </div>
    </div>
  );

  const showTemplatesView = (type) => {
    setTemplatesType(type);
    setShowModalTemplates(true);
  };

  const printPDFCover = async () => {
    loader.show(true);
    await printCover(expedientId);
    loader.show(false);
  };

  const handleOpenAssignUsers = async () => {
    setAssignUserSearch('');
    setUsersFound([]);
    await getUsersWithPermissionsToSeeExpedient(
      expedientId,
      setUsersWithPermission,
      loader
    );
    setShowModalAssignUsers(true);
  };

  const handleDowloadExpedient = async () => {
    loader.show(true);
    await dowloadExpedient(expedientId);
    loader.show(false);
  };
  
  const isFileOwnedByCurrentUser = String(dataExpedient['user_id']) === Cookies.get('userId_03');

  const actionButtonList = [
    {
      description: 'Imprimir carátula',
      icon: 'fas fa-print',
      onClick: printPDFCover,
      isShowing: true,
    },
    {
      description: getLabelFromDictionary('turned', 'turnExpedient'),
      icon: 'fas fa-share',
      onClick: () => {
        isInternalTurningByDefault.current = false
        transferState.handleOpenTransferModal()
      },
      isShowing: (!dataExpedient.external && !isATurnedExpedient),
    },
    {
      description: 'Indicadores',
      icon: 'fas fa-paste',
      onClick: () => setIsIndicatorsModalShowing(true),
      isShowing: canUseExpedientIndicators,
    },
    {
      description: 'Permisos de expediente',
      icon: 'fas fa-sliders-h',
      onClick: handleOpenAssignUsers,
      isShowing: (canAssignUsers && !isATurnedExpedient),
    },
    {
      description: 'Agregar documento',
      icon: 'fas fa-plus',
      onClick: showModalNewDocument,
      isShowing: (isFileOwnedByCurrentUser && !Boolean(dataExpedient.external) && !isATurnedExpedient && (canSign || isPermissionUploadDocumentsWithoutSignature)),
    },
    {
      description: getLabelFromDictionary('showCompleteExpedient', 'viewFullExpedient'),
      icon: 'fas fa-book-open',
      onClick: handleShowDocumentsView,
      isShowing: (isFileOwnedByCurrentUser && !Boolean(dataExpedient.external)),
    },
    {
      description: getLabelFromDictionary('showCompleteExpedient', 'downloadExpedient'),
      icon: 'fas fa-file-download',
      onClick: handleDowloadExpedient,
      isShowing: (isFileOwnedByCurrentUser && canDownloadExpedient),
    },
    {
      description: 'Generar código QR',
      icon: 'fas fa-qrcode',
      onClick: () => setIsGenerateQRModalShowing(true),
      isShowing: (isFileOwnedByCurrentUser && canDownloadExpedient),
    },
    {
      description: 'Listar Partes',
      icon: 'fas fa-clipboard-list',
      onClick: () => setShowModalParties(true),
      isShowing: canUseEditParties,
    },
  ];

  return (
    <>
      <NavBar {...props} />

      <div className="container-header-expedient-documents-details mT-10">
        <div className="text-center">
          <h3>
            {expedientNumber.replace(/-/g, '/')}
          </h3>
        </div>
        <div className="box-header-expedient-documents-details">
          <Button
            onClick={() => props.history.goBack()}
          >
            <i className="fas fa-arrow-left" /> Volver
          </Button>
        </div>
      </div>
      <br />

      <Row style={{ justifyContent: "center" }}>
        <Card
          className="cardExpedientDetails"
          id="coverPDF"
        >
          <Card.Body>
            <Container>
              <div>
                <Cover expedientId={expedientId} />
              </div>
              <br />
              {
                (Boolean(dataExpedient.history) && Boolean(dataExpedient.history.length))
                &&
                <>
                  <div>
                    <h5 style={{ fontWeight: 'bold' }}    >
                      Transferencia de {getLabelFromDictionary('global', 'sigleMainName')}
                    </h5>
                    <div className="table-responsive text-center"  >
                      <ChangeCourtTable expedientId={expedientId} changeCourtTableData={dataExpedient.history} />
                    </div>
                  </div>
                  <br />
                </>
              }
            </Container>
          </Card.Body>
        </Card>
      </Row>

      <Container style={{ maxInlineSize: '54rem', paddingTop: '6rem' }}>
        <ExpedientActions actionList={actionButtonList} />
      </Container>

      <FilesPendingSignatureTable
        handleOpenTransfer={transferState.handleOpenTransferModal}
        getDocumentsByExpedient={handleGetDocumentsByExpedientNumber}
      />

      {String(dataExpedient['user_id']) === Cookies.get('userId_03') ?
        <div className="optionsExpedientDocuments">
          <h4 className={"w-100 text-center" + (dataExpedient["external"] ? "" : " mB-30")}>
            {getLabelFromDictionary('detailExpedient', 'header')}
          </h4>
          <div className={"d-flex justify-content-between"} style={{ gap: '10px' }}>
            <div className="d-flex align-items-end" style={{ marginBottom: '3rem' }}>
              {
                canDownloadExpedient && isGenerateQRModalShowing
                &&
                <GenerateQRModal
                  electronicExpedientId={expedientId}
                  isShowingModal={isGenerateQRModalShowing}
                  onCloseModal={() => setIsGenerateQRModalShowing(false)}
                />
              }
            </div>
            <div style={{ flexBasis: '50%' }}>
              <ExpedientsSearcher
                placeholderSearch="Escribe el nombre del documento"
                placeholderSearchButton="Buscar documento"
                placeholderCleanButton="Limpiar"
                helperContextual="Escribe el nombre del documento y haz clic en el botón Buscar documento."
                executeSearch={searchDocuments}
                cleanSearch={cleanSearchDocuments}
                isSearching={false}
                isCleaning={false}
                setSearch={setSearch}
                search={search}
                hasRulesToSearch={false}
              />
            </div>
          </div>

          <div className="clearFloat" />
          {
            dataExpedient['external'] ? '' :
              <TemplatesPresavedContextProvider value={{ handleGetDocumentsByExpedientNumber }}>
                <TemplatesPresaved
                  expedientNumber={expedientNumber}
                  expedientId={expedientId}
                  reloadData={reloadDataTemplates}
                  setReloadData={setReloadDataTemplates}
                  reloadParentComponent={reloadComponent}
                  props={props}
                  dataExpedient={dataExpedient}
                  reloadExpedientDetailsComponent={reloadComponent}
                  onShowCreateTemplateByTypeModal={showTemplatesView}
                  isATurnedExpedient={isATurnedExpedient}
                />
              </TemplatesPresavedContextProvider>
          }
        </div> : <div className="mT-50" />
      }

      <Grid fluid={true}
        className={expedientTableDocuments.length > 0 ? "no-padding" : "d-n"}
        style={{ minHeight: '76vh', width: "65%", margin: "0px auto" }}>
        <section className="document-expedient-table procedures-table-container tableFixHead mB-50 mT-10">
          <RemotePagination
            data={expedientTableDocuments}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={totalDocuments}
            onTableChange={handleTableChange}
            columns={columns}
          />

        </section>
      </Grid>

      <h4
        className={dataDocuments.length === 0 && textNotDataDocuments !== "" ? "text-center clearFloat padding-footer-not-data-documents mT-10" : "d-n"}>
        {textNotDataDocuments}
      </h4>
      <Footer />

      <FullCommentModal
        comment={commentModal}
        showModal={showCommentModal}
        setShowModal={setShowCommentModal}
      />

      <IndicatorsModal
        toggleShowinModal={() => setIsIndicatorsModalShowing(!isIndicatorsModalShowing)}
        isModalShowing={isIndicatorsModalShowing}
        expedientId={dataExpedient.id}
      />

      {dataExpedient && dataExpedient.id &&
        <PartiesModal
          showModal={showModalParties}
          toggleShowModal={() => setShowModalParties(!showModalParties)}
          idExpedient={dataExpedient.id}
        />
      }
      
      <ModalComponent
        header={`Documentos del ${getLabelFromDictionary('global', 'mainName')}`}
        body={bodyDocumentsView(
          currentDocument, setCurrentDocument,
          documentsView, pauseCarousel, setPauseCarousel
        )}
        footer={footerDocumentsView(setCloseDocumentsView)}
        show={showDocumentsView}
        canClose={true}
        onHide={setCloseDocumentsView}
        dialogClassName="modal-templates-preview"
      />

      <SimpleSignatureAlert
        isModalShowing={isSimpleSignatureAlertShowing}
        setIsModalShowing={setIsSimpleSignatureAlertShowing}
        onCancel={onCancelSimpleSignatureAlertClick}
        onContinue={onContinueSimpleSignatureAlertClick}
      />

      <TransferModal {...transferState} expedientParts={expedientParties} />

      <ModalComponent
        header="Nuevo documento"
        body={bodyModal}
        footer={footerModal}
        show={show}
        canClose={true}
        onHide={setCloseModal}
      />

      <ModalComponent
        header="Documentos de pruebas"
        body={bodyModalDocuments(showDocuments ? documentsState : [])}
        footer={footerModalDocuments(closeModalDocuments)}
        show={showDocuments}
        canClose={true}
        onHide={setShowDocuments}
        scroll={true}
      />

      <ModalComponent
        header="Documentos relacionados"
        body={bodyModalDocuments(showRelationDocuments ? documentsState : [])}
        footer={footerModalDocuments(setShowRelationDocuments)}
        show={showRelationDocuments}
        canClose={true}
        onHide={setShowRelationDocuments}
        scroll={true}
      />

      <AlertModal
        isShowingModal={isPublicJudgmentAlertModalShowing}
        title={'Alerta'}
        content={
          <h4>
            Has seleccionado subir un documento de sentencia en su versión pública. Asegúrate que el documento que selecciones para cargar desde tu ordenador, sea un documento testado
          </h4>}
        onAccept={sendFile}
        onCancel={() => setIsPublicJudgmentAlertModalShowing(false)}
        onCloseModal={() => setIsPublicJudgmentAlertModalShowing(false)}
      />

      <ModalComponent
        header="Notificaciones:"
        body={bodyModalNotifications(showModalNotifications ? documentsState : [])}
        footer={footerModalNotifications(setShowModalNotifications)}
        show={showModalNotifications}
        canClose={true}
        onHide={setShowModalNotifications}
        scroll={true}
        dialogClassName="modal-templates-preview"
      />

      <ModalComponent
        header="Vencimientos"
        body={bodyModalExpiration(showExpirationDocuments ? documentsState : [])}
        footer={footerModalDocuments(setShowExpirationDocuments)}
        show={showExpirationDocuments}
        canClose={true}
        onHide={setShowExpirationDocuments}
        scroll={true}
      />

      <ModalComponentPortal
        header={`Plantillas de tipo ${templatesType === 'agreement' ? 'acuerdo:' :
          templatesType === 'notification' ? 'notificación:' :
            templatesType === 'email' ? 'correo electrónico:' :
              templatesType === 'promotion' ? 'promoción:' : 'sentencia:'}`}
        body={<PreviewTemplates
          type={templatesType}
          expedientNumber={expedientNumber}
          expedientId={expedientId}
          setReloadDataTemplates={setReloadDataTemplates}
          reloadDataTemplates={reloadDataTemplates}
          setShowModalTemplates={setShowModalTemplates}
        />}
        footer={(
          <>
            <Button variant="secondary" onClick={() => setShowModalTemplates(false)}>Cerrar</Button>
          </>
        )}
        isModalShowing={showModalTemplates}
        onHide={setShowModalTemplates}
      />

      <AssignUserModal
        loader={loader}
        expedientId={expedientId}
        usersWithPermission={usersWithPermission}
        setUsersWithPermission={setUsersWithPermission}
        usersFound={usersFound}
        setUsersFound={setUsersFound}
        assignUserSearch={assignUserSearch}
        setAssignUserSearch={setAssignUserSearch}
        showModal={showModalAssignUsers}
        setShowModal={setShowModalAssignUsers}
      />

      {
        showModalSendNotifications
        &&
        <NotifyEmailModal
          showModal={showModalSendNotifications}
          expedientId={expedientId}
          handleOnCancel={() => setShowModalSendNotifications(false)}
          onAcceptClick={onClickNotificationModalAcceptButton}
        />
      }

      {
        isUsersAndPermissionsListModalShowing
        &&
        <UsersAndPermissionsListModal
          isModalShowing={isUsersAndPermissionsListModalShowing}
          setModalShowing={setIsUsersAndPermissionsListModalShowing}
          document={currentDocumentFromDocumentExpedientTable}
          expedientId={expedientId}
        />
      }

      <ModalComponent
        header={(<h3 className="text-center">Advertencia</h3>)}
        body={(
          <div>
            <h4 className="text-center">¿Desea agregar un acuerdo sin contestar una promoción?</h4>
          </div>
        )}
        footer={(
          <>
            <Button
              variant="secondary"
              onClick={() => setShowModalConfirm(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="success"
              onClick={() => {
                hasAgreementWithoutAnsweringPromotionModalBeenAccepted.current = true
                signDocument();
                setShowModalConfirm(false);
              }}
            >
              Aceptar
            </Button>
          </>
        )}
        show={showModalConfirm}
        canClose={true}
        onHide={() => {
          setSelectedPromotions([]);
          setShowModalConfirm(false);
        }}
        dialogClassName="modal-templates-preview"
      />

      <Loader />
    </>
  );
};

export default ExpedientDetails;
