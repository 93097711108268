import React, { useEffect, useState } from 'react';
import FormTitle from '../../FormTitle';
import { Form } from 'react-bootstrap';
import { useIndicatorsContext } from '../../../context/indicatorsContext/IndicatorsContext';
import _ from 'lodash';
import { finalJudgmentDefaultFormValues, interlocutoryJudgmentDefaultFormValues } from '../../../constants/defaultValues';
import { renderErrorsByInputName } from '../../../../../../my_expedients/expedients_list_logic';
import { PrimitivesEntity } from '../../../../../../../../shared/entities/primitives.entity';
import { CrimesMultiselect } from '../../../../../../../shared/crimes/CrimesMultiselect';
import { UserEntity } from '../../../../../../../../shared/entities/user.entity';

const InterlocutoryJudgment = () => {
  const { interlocutoryJudgment, finalJudgment } = useIndicatorsContext();
  const [userMatters, setUserMatters] = useState([]);
  const {
    interlocutoryJudgmentFormMethods: {
      onFormChange,
      onFormRadioChange,
      formValues,
      onFormCheckChange
    },
    assertions,
    errors,
  } = interlocutoryJudgment;
  const {
    finalJudgmentFormMethods: {
      formValues: formValuesInterlocutoryJudgment,
      onFormChangeWithKeyValue
    }
  } = finalJudgment;

  const {
    isASenseResolutionpProceeded,
    isNotASenseResolutionpProceeded,
  } = assertions;

  useEffect(() => {
    const getMatters = async () => {
      const matters = await UserEntity.getMyMatter();
      setUserMatters(matters);
    }
    getMatters();
  }, []);

  return (
    <div>
      <FormTitle>
        Sentencia interlocutoria
      </FormTitle>

      <Form>
        <Form.Group controlId="precautionary_measures_decreed">
          <Form.Label>
            Medidas precautorias decretadas
          </Form.Label>
          <Form.Control
            type="number"
            name='precautionary_measures_decreed'
            onChange={onFormChange}
            value={_.defaultTo(formValues.precautionary_measures_decreed, interlocutoryJudgmentDefaultFormValues.precautionary_measures_decreed)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName(errors, 'precautionary_measures_decreed')}
        </Form.Group>

        <Form.Group controlId="precautionary_measures_requested">
          <Form.Label>
            Medidas precautorias solicitadas
          </Form.Label>
          <Form.Control
            type="number"
            name='precautionary_measures_requested'
            onChange={onFormChange}
            value={_.defaultTo(formValues.precautionary_measures_requested, interlocutoryJudgmentDefaultFormValues.precautionary_measures_requested)}
          />
          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
          {renderErrorsByInputName(errors, 'precautionary_measures_requested')}
        </Form.Group>

        <Form.Group controlId="precautory_measures_denied">
          <Form.Label>
            Medidas precautorias negadas
          </Form.Label>
          <Form.Control
            type="number"
            name='precautory_measures_denied'
            onChange={onFormChange}
            value={_.defaultTo(formValues.precautory_measures_denied, interlocutoryJudgmentDefaultFormValues.precautory_measures_denied)}
          />

          <Form.Text className="text-muted">
            El dato en este campo debe ser un número
          </Form.Text>
        </Form.Group>
        {userMatters.includes('penal') &&
          <Form.Group controlId="principal_action">
            <CrimesMultiselect
              isDisabled={false}
              matter={'penal'}
              changeMainForm={onFormChangeWithKeyValue}
              defaultCrimes={_.defaultTo(formValuesInterlocutoryJudgment.crimes, finalJudgmentDefaultFormValues.crimes)}
              isError={false}
              isUpdate={true}
              handleChangeUpdate={onFormChangeWithKeyValue}
            />
          </Form.Group>
        }
        <Form.Group controlId="sense_resolution">
          <Form.Text className="text-muted">
            Sentido de la resolución
          </Form.Text>
          <Form.Check
            inline
            type="radio"
            label="Procedente"
            name="sense_resolution"
            id='sense_resolution_proceed'
            value={'proceed'}
            checked={isASenseResolutionpProceeded}
            onChange={onFormRadioChange}
          />
          <Form.Check
            inline
            type="radio"
            label="Improcedente"
            name="sense_resolution"
            id='sense_resolution_no_proceed'
            value={'no_proceed'}
            checked={isNotASenseResolutionpProceeded}
            onChange={onFormRadioChange}
          />
        </Form.Group>
      </Form>
    </div>
  )
}

export default InterlocutoryJudgment