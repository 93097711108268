import { useState } from 'react'

const useFormValues = (defaultFormValues) => {

  const [formValues, setFormValues] = useState(defaultFormValues);

  const onFormChange = ({ target } = {}) => {
    if (typeof target !== 'object') {
      return
    }
    const name = target.name;
    const value = target.value;
    setFormValues((previousState) => ({ ...previousState, [name]: value }));
  }

  const onFormChangeWithKeyValue = (key = '', value) => {
    const target = {
      name: key,
      value: value,
    }
    onFormChange({ target });
  }

  const onFormRadioChange = ({ target } = {}) => {
    if (typeof target !== 'object') {
      return
    }
    let fieldName;
    if (target.dataset && target.dataset.fieldname) {
      fieldName = target.dataset.fieldname;
    }
    const name = fieldName || target.name;
    const value = target.value;
    onFormChangeWithKeyValue(name, value);
  }

  const onFormCheckChange = ({ target } = {}) => {
    if (typeof target !== 'object') {
      return
    }
    const name = target.name;
    const value = target.checked;
    onFormChangeWithKeyValue(name, value);
  }
  
  const onFormCheckListChange = ({ target } = {}) => {
    if (typeof target !== 'object') {
      return
    }
    const name = target.name;
    const value = target.value;

    let previousCheckList = formValues[name];
    if (!Array.isArray(previousCheckList)) {
      previousCheckList = [];
    }

    const isCheckValueInPreviousCheckList = previousCheckList.includes(value);

    let newCheckList = previousCheckList;
    if (isCheckValueInPreviousCheckList) {
      newCheckList = previousCheckList.filter(checkValue => checkValue !== value);
    }
    if (!isCheckValueInPreviousCheckList) {
      newCheckList = previousCheckList.concat(value);
    }

    onFormChangeWithKeyValue(name, newCheckList);
  }

  const onSelectChange = (selectedOption, select) => {
    if (typeof selectedOption !== 'object' || typeof selectedOption !== 'object') {
      return
    }
    const name = select.name;
    const value = selectedOption.value;
    onFormChangeWithKeyValue(name, value);
  }

  const resetFormValues = () => {
    setFormValues(defaultFormValues);
  }

  return ({
    onFormChange,
    onFormRadioChange,
    onFormCheckChange,
    onSelectChange,
    onFormChangeWithKeyValue,
    setFormValues,
    formValues,
    resetFormValues,
    onFormCheckListChange,
  })
}

export default useFormValues;