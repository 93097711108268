import { useState } from 'react';
import useFormValues from '../../../../../../hooks/useFormValues';
import _ from 'lodash';
import { initialDemandsDefaultValues, legalEntityDefaultValues } from '../constants/defaultValues';

const useInitialDemand = () => {

  const [isAddMinorModalShowing, setIsAddMinorModalShowing] = useState(false);
  const initialDemands = useFormValues(initialDemandsDefaultValues);
  const actorLegalEntity = useFormValues(legalEntityDefaultValues);
  const defendantLegalEntity = useFormValues(legalEntityDefaultValues);
  const therdLegalEntity = useFormValues(legalEntityDefaultValues);

  const formValues = {
    ...initialDemands.formValues,
    actor_legal_entity: actorLegalEntity.formValues,
    defendant_legal_entity: defendantLegalEntity.formValues,
    therd_legal_entity: therdLegalEntity.formValues,
  }

  const assertions = {
    isThereAMinorInvolved: String(initialDemands.formValues.minors) === 'true',
    isNotThereAMinorInvolved: String(initialDemands.formValues.minors) === 'false',

    isAnActor: String(initialDemands.formValues.actor) === 'true',
    isAMaleActor: actorLegalEntity.formValues.gender === 'male',
    isAFemaleActor: actorLegalEntity.formValues.gender === 'female',
    doesActorBelongToAnCommunity: String(actorLegalEntity.formValues.community) === 'true',
    doesNotActorBelongToAnCommunity: String(actorLegalEntity.formValues.community) === 'false',

    isADefendant: String(initialDemands.formValues.defendant) === 'true',
    isAMaleDefendant: defendantLegalEntity.formValues.gender === 'male',
    isAFemaleDefendant: defendantLegalEntity.formValues.gender === 'female',
    doesDefendantBelongToAnCommunity: String(defendantLegalEntity.formValues.community) === 'true',
    doesNotDefendantBelongToAnCommunity: String(defendantLegalEntity.formValues.community) === 'false',

    isAThird: String(initialDemands.formValues.third) === 'true',
    isAMaleThird: therdLegalEntity.formValues.gender === 'male',
    isAFemaleThird: therdLegalEntity.formValues.gender === 'female',
    doesThirdBelongToAnCommunity: String(therdLegalEntity.formValues.community) === 'true',
    doesNotThirdBelongToAnCommunity: String(therdLegalEntity.formValues.community) === 'false',
  }

  const handleAddMinor = (minor = {}) => {
    let previousInvolvedMinors = initialDemands.formValues.involved_minors;
    if (!Array.isArray(previousInvolvedMinors)) {
      previousInvolvedMinors = [];
    }
    const newInvolvedMinors = [...previousInvolvedMinors, minor];
    initialDemands.onFormChangeWithKeyValue('involved_minors', newInvolvedMinors);
  }

  const toggleisAddMinorModalShowing = () => {
    setIsAddMinorModalShowing(!isAddMinorModalShowing);
  }

  const setInitialState = (initialState) => {
    const doesInitialStateExist = _.isObject(initialState);
    if (!doesInitialStateExist) {
      return
    }

    const {
      actor_legal_entity = {},
      defendant_legal_entity = {},
      therd_legal_entity = {},
      ...initial_demands
    } = initialState;

    initialDemands.setFormValues({ ...initialDemands.formValues, ...initial_demands });
    actorLegalEntity.setFormValues({ ...actorLegalEntity.formValues, ...actor_legal_entity });
    defendantLegalEntity.setFormValues({ ...defendantLegalEntity.formValues, ...defendant_legal_entity });
    therdLegalEntity.setFormValues({ ...therdLegalEntity.formValues, ...therd_legal_entity });
  }

  return {
    initialDemands,
    actorLegalEntity,
    defendantLegalEntity,
    therdLegalEntity,
    assertions,
    formValues,
    handleAddMinor,
    isAddMinorModalShowing,
    toggleisAddMinorModalShowing,
    setInitialState,
  }
}

export default useInitialDemand